import * as React from "react";
import { useState, useEffect } from "react";
import { Button, Card, Heading, Text, IconButton, majorScale, Pane, PlusIcon, Select, StatusIndicator, Textarea, TextInput, TrashIcon, Popover, Position, Menu, CaretDownIcon, CogIcon, toaster } from "evergreen-ui";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { ISurvey } from "./interfaces.interface";
import { DataService } from "./Auth/services";

export default function Dashboard() {
  const [surveys, setSurveys] = useState<ISurvey[]>([]);
  let navigate = useNavigate();

  /*   useEffect(() => {
    fetch("https://63f72f24e8a73b486af23661.mockapi.io/surveys", {
      method: "GET",
      headers: { "content-type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        // handle error
      })
      .then((surveys) => {
        // Do something with the list of surveys
        setSurveys(surveys);
      })
      .catch((error) => {
        // handle error
      });
  }, []); */

  useEffect(() => {
    DataService.getSurveys().then((res) => {
      setSurveys(res);
    });
  }, []);

  const copyFillLink = (survey, targetGroup) => {
    navigator.clipboard.writeText(window.location.hostname + "/fill/" + targetGroup.id);
    toaster.success(`A(z) ${survey.title} kérdőív kitöltő linkje vágólapra másolva a(z) ${targetGroup.name} célcsoport számára`);
  };

  const handleDeleteSurvey = (survey: ISurvey) => {
    const result = window.confirm(`Biztos vagy benne, hogy törlöd a(z) "${survey.title}" kérdőívet?`);

    if (result) {
      console.log("User selected OK.");
      DataService.removeSurvey(survey.id).then((res) => {
        console.log(res);
        navigate(0);
      });
    } else {
      console.log("User cancelled");
    }
  };

  return (
    <Pane display={"grid"} gap={majorScale(2)}>
      <Button
        appearance={"primary"}
        size={"large"}
        onClick={() => {
          navigate("/create");
        }}
      >
        Új kérdőív létrehozása
      </Button>
      <Pane display={"grid"} gap={majorScale(2)}>
        {surveys?.map((survey: ISurvey) => {
          return (
            <Card key={survey.id} background={"white"} padding={majorScale(2)} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <Pane>
                <Heading>{survey.title}</Heading>
                <Text>{survey.description}</Text>
              </Pane>
              <Pane display={"flex"} gap={majorScale(1)}>
                <Button onClick={() => navigate("/results/" + survey.id)}>Eredmények</Button>
                <Popover
                  position={"bottom-left"}
                  content={
                    <Menu>
                      <Menu.Group>
                        {survey.targetGroups?.map((targetGroup, index) => {
                          return (
                            <Menu.Item key={targetGroup.id} onSelect={() => copyFillLink(survey, targetGroup)} icon={<StatusIndicator dotSize={16} color={index === 0 ? "#3366FF" : "#FFB020"} />}>
                              {targetGroup.name}
                            </Menu.Item>
                          );
                        })}
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <Button iconAfter={CaretDownIcon}>Kitöltő link másolása</Button>
                </Popover>

                <Popover
                  position={"bottom-left"}
                  content={
                    <Menu>
                      <Menu.Group>
                        <Menu.Item icon={TrashIcon} intent="danger" onClick={() => handleDeleteSurvey(survey)}>
                          Kérdőív törlése
                        </Menu.Item>
                      </Menu.Group>
                    </Menu>
                  }
                >
                  <IconButton icon={CogIcon} />
                </Popover>
              </Pane>
            </Card>
          );
        })}
      </Pane>
    </Pane>
  );
}
