import { Button, majorScale, Card, TextInput, Heading, Pane } from "evergreen-ui";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "./Auth/AuthContext";
import { DataService } from "./Auth/services";

export default function Register() {
  const { id } = useParams();
  const [isInviteValid, setIsInviteValid] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { signup, currentUser } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser) navigate("/");
    id &&
      DataService.checkInvitationCode(id).then((res) => {
        setIsInviteValid(res);
        if (!res) {
          navigate("/");
        }
      });
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await signup(emailRef.current?.value, passwordRef.current?.value);
      window.alert("Sikeres regisztráció! Most már be tudsz lépni.");
      id && DataService.removeInvitationCode(id);
      navigate("/");
    } catch {
      setError("Hiba történt...");
      window.alert("Hiba történt...");
    }

    setLoading(false);
  };

  return (
    <Card background="white" display={"grid"} gap={majorScale(2)} padding={majorScale(2)}>
      <form onSubmit={handleSubmit}>
        <Pane display={"grid"} gap={majorScale(2)}>
          <Heading>Regisztráció</Heading>
          <TextInput placeholder="Név" required ref={nameRef} width="100%" />
          <TextInput placeholder="E-mail cím" required type={"email"} ref={emailRef} width="100%" />
          <TextInput placeholder="Jelszó" required type={"password"} ref={passwordRef} width="100%" />
          <Button appearance="primary" size="large" type="submit">
            Regisztráció
          </Button>
        </Pane>
      </form>
    </Card>
  );
}
