import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAVsp82GunqxMMVe_utkRY0viEJqltDg0c",
  authDomain: "tukrozo.firebaseapp.com",
  projectId: "tukrozo",
  storageBucket: "tukrozo.appspot.com",
  messagingSenderId: "629009613051",
  appId: "1:629009613051:web:3d503cf5152d58c822305e",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();

export default app;
