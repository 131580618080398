import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./Auth/AuthContext";

export default function PrivateRoutes() {
  const { currentUser } = useAuth();
  console.log(currentUser);

  return currentUser ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
}
