import { Heading, Button, Card, majorScale, Pane, Text, Textarea, Table } from "evergreen-ui";
import { ReactEventHandler, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { DataService } from "./Auth/services";

export default function TargetGroups() {
  const [enteredLines, setEnteredLines] = useState(0);
  const [enteredGroups, setEnteredGroups] = useState<{ id: string; name: string }[]>([]);
  const [targetGroups, setTargetGroups] = useState<{ id: string; name: string }[]>([]);
  const [isLoading, setIsloading] = useState(true);

  const placeholder = "Írjon be soronként célcsoportokat... például:\n\nKecskeméti Barackvirág Evangélikus Óvoda\nKék Elefánt Evangélikus Óvoda (Pécs)";

  const textArea = useRef<HTMLTextAreaElement>(null);
  const navigator = useNavigate();

  const handleTextareaChange = () => {
    if (textArea.current) {
      const text = textArea.current.value;
      const lines = text.split("\n").length;
      const nonEmptyLines = text.split("\n").filter((line) => line.trim().length > 0);

      textArea.current.rows = lines;
      setEnteredLines(nonEmptyLines.length);
      setEnteredGroups(() => nonEmptyLines.map((line) => ({ id: uuidv4(), name: line })));

      console.log(enteredGroups);

      console.log("Number of lines entered:", lines);
    }
  };

  const handleAddTargetGroups = () => {
    DataService.submitTargetGroups(enteredGroups).then((res) => {
      window.alert("Sikeres hozzáadás!");
      navigator(0);
    });
  };

  useEffect(() => {
    DataService.getTargetGroups().then((res) => {
      const orderByName = res.sort((a, b) => a.name.localeCompare(b.name));
      setTargetGroups(orderByName);
      setIsloading(false);
    });
  }, []);

  return (
    <Pane display={"grid"} gap={majorScale(2)}>
      <Card background="white" display={"grid"} gap={majorScale(2)} padding={majorScale(2)}>
        <Pane display={"grid"} gap={majorScale(2)}>
          <Pane display={"flex"} justifyContent="space-between" alignItems="center">
            <Heading>Célcsoport hozzáadása</Heading>
            <Button disabled={enteredLines === 0} onClick={handleAddTargetGroups}>
              {enteredLines} célcsoport hozzáadása
            </Button>
          </Pane>
          <Textarea name="textarea" placeholder={placeholder} ref={textArea} onChange={handleTextareaChange} />
        </Pane>
      </Card>
      <Card background="white" display={"grid"} gap={majorScale(2)} padding={majorScale(2)} overflow="hidden">
        <Pane display={"flex"} gap={majorScale(2)} width="100%" flexDirection="column" overflow="Hidden">
          <Heading>Hozzáadott célcsoportok</Heading>
          <Table maxWidth={"100%"}>
            <Table.Head>
              <Table.TextHeaderCell>Célcsoport</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {targetGroups.map((group, index) => (
                <Table.Row key={index} isSelectable height={majorScale(4)}>
                  <Table.TextCell>{group.name}</Table.TextCell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Pane>
      </Card>
    </Pane>
  );
}
