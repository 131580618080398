import { Bar, BarChart, CartesianGrid, Label, LabelList, Legend, Line, Rectangle, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { IFill } from "./interfaces.interface";

const transformAnswersToChartData = (answers, targetGroups) => {
  let data: any[] = [];
  answers.forEach((answer) => {
    answer.answers.forEach((option: any) => {
      const optionIndex = data.findIndex((obj: any) => obj.option === option.option);

      if (optionIndex === -1) {
        const newData = {
          option: option.option,
        };
        targetGroups.forEach((targetGroup) => {
          if (targetGroup.id === answer.targetGroupId) {
            newData[targetGroup.name] = 1;
          } else {
            newData[targetGroup.name] = 0;
          }
        });
        data.push(newData);
      } else {
        targetGroups.forEach((targetGroup) => {
          if (targetGroup.id === answer.targetGroupId) {
            data[optionIndex][targetGroup.name] = (data[optionIndex][targetGroup.name] || 0) + 1;
          }
        });
      }
    });
  });
  data.sort((a, b) => (a.option > b.option ? 1 : -1));
  return data;
};

export default function Chart({ targetGroups, answers, options, fills }: { targetGroups: any; answers: any; options: any; fills: IFill[] }) {
  //const data = transformAnswersToChartData(answers, targetGroups);

  const theTargetGroups = Array.from(new Set(fills.map((obj) => obj.targetGroupId)));
  console.log(options);
  const theOptions = Array.from(new Set(options.flatMap((option) => option.value)));
  const chartData = theOptions.map((option) => {
    const groupCounts = theTargetGroups.reduce((counts, targetGroupId) => {
      counts[targetGroupId] = 0;
      return counts;
    }, {});

    fills.forEach((obj) => {
      const matchingAnswer = obj.answers.find((answer) => answer.option === option);

      if (matchingAnswer) {
        groupCounts[obj.targetGroupId]++;
      }
    });

    return {
      option,
      ...groupCounts,
    };
  });

  console.log(chartData);

  return (
    <ResponsiveContainer width={"100%"} minHeight={80 * options.length}>
      <BarChart data={chartData} layout="vertical" stackOffset="expand">
        <Legend verticalAlign="top" />
        <XAxis type="number" />
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis type="category" dataKey="option"></YAxis>
        <Tooltip />
        {targetGroups.map((targetGroup, index) => {
          return <Bar key={targetGroup.id} stackId={"a"} name={targetGroup.name} dataKey={targetGroup.id} fill={index === 0 ? "#3366FF" : "#FFB020"} maxBarSize={24}></Bar>;
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}
