import { Button, majorScale, Card, TextInput, Heading, Pane, Alert } from "evergreen-ui";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./Auth/AuthContext";

export default function Login() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { login, currentUser } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser) navigate("/dashboard");
  }, []);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await login(emailRef.current?.value, passwordRef.current?.value);
      navigate("/");
    } catch (error) {
      console.log(error);
      setError("Kérlek ellenőrizd a megadott az e-mail címet, jelszót.");
    }

    setLoading(false);
  };

  return (
    <Card background="white" display={"grid"} gap={majorScale(2)} padding={majorScale(2)}>
      <form onSubmit={handleSubmit}>
        <Pane display={"grid"} gap={majorScale(2)}>
          <Heading>Belépés</Heading>
          {error && (
            <Alert intent="danger" title="Hiba történt">
              {error}
            </Alert>
          )}
          <TextInput placeholder="E-mail cím" required type="email" ref={emailRef} width="100%" />
          <TextInput placeholder="Jelszó" required type="password" ref={passwordRef} width="100%" />
          <Button appearance="primary" size="large" type="submit">
            Belépés
          </Button>
        </Pane>
      </form>
    </Card>
  );
}
