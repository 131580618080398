import { Button, Card, Heading, majorScale, Pane, Text, StatusIndicator, Tooltip, toaster } from "evergreen-ui";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { DataService } from "./Auth/services";
import Chart from "./Chart";
import { ISurvey, IFill } from "./interfaces.interface";

const getQuestionOverlap = (question, fills, countQuestionResponses) => {
  let counts: number[] = [];

  for (let o = 0; o < question.options.length; o++) {
    const optionItem = question.options[o];
    const optionCountPerTargetGroup = getCountOptionPerTargetGroup(optionItem.id, fills);
    const hasZeroCountFromAnyGroup = hasZeroValue(optionCountPerTargetGroup);

    // Összesen hányan válaszoltak erre az opcióra, ha egyik csoportban üres, menj tovább;
    if (!hasZeroCountFromAnyGroup) {
      const overlap = (sumObjectValues(optionCountPerTargetGroup) / countQuestionResponses) * 100;
      counts.push(overlap);
    } else {
      console.log(optionItem.value, " kimarad");
    }
  }

  if (counts.length > 0) {
    return getAverage(counts);
  }
};

const hasZeroValue = (obj) => {
  for (const key in obj) {
    if (obj[key] === 0) {
      return true;
    }
  }

  return false;
};

const sumObjectValues = (obj) => {
  let sum = 0;
  for (const key in obj) {
    if (typeof obj[key] === "number") {
      sum += obj[key];
    }
  }

  return sum;
};

const getAverage = (array): number => {
  const sum = array.reduce((x, y) => x + y, 0);
  const average = sum / array.length;

  return Number(average.toFixed(2));
};

const getCountQuestionResponses = (questionId, fills) => {
  let sum = 0;
  fills.forEach((fill: IFill) => {
    const answer = fill.answers.find((answer) => answer.questionId === questionId);
    if (answer) {
      sum++;
    }
  });
  return sum;
};

const getCountOptionPerTargetGroup = (optionId, fills) => {
  const counts: { [targetGroupId: string]: number } = {};

  fills.forEach((fill: IFill) => {
    const { targetGroupId, answers } = fill;
    const selectedAnswers = answers.filter((answer) => answer.optionId === optionId);

    if (selectedAnswers.length > 0) {
      if (!counts[targetGroupId]) {
        counts[targetGroupId] = 0;
      }

      counts[targetGroupId] += selectedAnswers.length;
    } else {
      if (!counts[targetGroupId]) {
        counts[targetGroupId] = 0;
      }
    }
  });

  return counts;
};

const getNumberOfFillsByGroup = (fills, group) => {
  return fills.filter((fill) => fill.targetGroupId === group.id).length;
};

const getAnswersForQuestion = (fills, questionId) => {
  const filteredFills = fills.filter((fill) => fill.answers.some((answer) => answer.questionId === questionId));
  const reducedFills = filteredFills.map((fill) => {
    const answers = fill.answers.filter((answer) => answer.questionId === questionId);
    return { ...fill, answers };
  });
  return reducedFills;
};

interface PerformanceSummary {
  rate: null | number;
  option: string;
  optionId: string;
  performance: {
    [targetGroupId: string]: number;
  };
}

const calculatePerformance = (survey, fills, callback) => {
  // Get unique options from the survey
  const options: { value: string; id: string }[] = Array.from(new Set(survey.questions.flatMap((question) => question.options.map((option) => ({ value: option.value, id: option.id })))));

  // Initialize performance summary
  const performanceSummary: PerformanceSummary[] = options.map((option) => ({
    option: option.value,
    optionId: option.id,
    performance: {},
    rate: null,
  }));

  // Calculate performance per target group
  fills
    .filter((fill) => fill.surveyId === survey.id && survey.targetGroups.some((group) => group.id === fill.targetGroupId))
    .forEach((fill) => {
      fill.answers.forEach((answer) => {
        const question = survey.questions.find((q) => q.id === answer.questionId);
        if (question) {
          const option = question.options.find((option) => option.id === answer.optionId);
          if (option) {
            const optionIndex = performanceSummary.findIndex((summary) => summary.option === option.value);
            if (optionIndex !== -1) {
              const { targetGroupId } = fill;
              const targetGroup = survey.targetGroups.find((group) => group.id === targetGroupId);
              if (targetGroup) {
                performanceSummary[optionIndex].performance[targetGroup.name] = (performanceSummary[optionIndex].performance[targetGroup.name] || 0) + 1;
              }
            }
          }
        }
      });
    });

  // Filter out options that were not chosen by both target groups
  const filteredSummary = performanceSummary.filter((summary) => survey.targetGroups.every((targetGroup) => summary.performance[targetGroup.name] !== undefined));

  for (let s = 0; s < filteredSummary.length; s++) {
    const performanceItem = filteredSummary[s];

    let count = 0;
    for (let val in performanceItem.performance) {
      count = count + performanceItem.performance[val];
    }

    performanceItem.rate = (count / fills.length) * 100;
  }

  console.log("Summary: ", filteredSummary);
  callback(filteredSummary);
};

const calculateOverallPerformance = (performance: PerformanceSummary[], callback: Function) => {
  let count = 0;
  for (let r = 0; r < performance.length; r++) {
    const relPerfItem = performance[r];

    if (relPerfItem.rate) {
      count = count + relPerfItem.rate;
    }
  }
  callback(count / performance.length);
};

const Results = () => {
  let { id } = useParams<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [survey, setSurvey] = useState<ISurvey | null>();
  const [fills, setFills] = useState<IFill[] | null>([]);
  const [overlap, setOverlap] = useState(0);
  const overlaps = useRef<number[]>([]);

  const [performance, setPerformance] = useState<PerformanceSummary[] | null>(null);
  const [overallPerformance, setOverallPerformance] = useState<number | null>(null);

  useEffect(() => {
    if (id) {
      DataService.getSurveyWithFills(id).then(([survey, fills]) => {
        if ([survey, fills]) {
          setSurvey(survey);
          setFills(fills);
        }
        setIsLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    const avg = getAverage(overlaps.current);
    setOverlap(avg);
  }, [overlaps]);

  const copyFillLink = (targetGroup) => {
    navigator.clipboard.writeText(window.location.hostname + "/fill/" + targetGroup.id);
    toaster.success(`Kitöltő link vágólapra másolva a(z) ${targetGroup.name} célcsoport számára`);
  };

  useEffect(() => {
    if (survey && fills) {
      calculatePerformance(survey, fills, (result) => {
        setPerformance(result);
      });
    }
  }, [survey, fills]);

  useEffect(() => {
    if (performance) {
      calculateOverallPerformance(performance, (result) => {
        setOverallPerformance(result);
      });
    }
  }, [performance]);

  const getQuestionPerformance = (question, performance) => {
    const questionOptions = question.options.flatMap((option) => option.id);
    const relatedPerformance = performance.filter((option) => questionOptions.includes(option.optionId));

    let count = 0;
    for (let r = 0; r < relatedPerformance.length; r++) {
      const relPerfItem = relatedPerformance[r];

      count = count + relPerfItem.rate;
    }
    return count / relatedPerformance.length;
  };

  //

  if (isLoading) {
    return <Pane>Betöltés...</Pane>;
  }

  return (
    <Pane display={"flex"} flexDirection={"column"} gap={majorScale(2)}>
      {survey && fills && (
        <Pane display={"flex"} flexDirection={"column"} gap={majorScale(2)}>
          <Card background={"white"} padding={majorScale(2)} display={"flex"} gap={majorScale(2)} justifyContent={"space-between"}>
            <Pane display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
              <Pane>
                <Heading>{survey.title}</Heading>
                <Text>{survey.description}</Text>
              </Pane>
              <Pane display={"grid"}>
                <Text>
                  Kitoltes időszaka: {survey.startDate} - {survey.endDate}
                </Text>
                <Text>Kitöltők szama: {fills.length}</Text>
                {survey.contact && <Text>Kapcsolattartó: {survey.contact}</Text>}
                <Button marginTop={majorScale(2)} onClick={(e) => window.print()}>
                  Oldal nyomtatása
                </Button>
              </Pane>
            </Pane>
            {survey.targetGroups.length > 1 && (
              <Pane padding={majorScale(4)}>
                <Pane background={"gray200"} position={"relative"} padding={majorScale(8)} borderRadius={majorScale(80)} overflow="hidden">
                  <Heading size={900} position={"absolute"} left={0} top={0} width={"100%"} height={"100%"} display={"flex"} alignItems={"center"} justifyContent="center" zIndex="1">
                    {overallPerformance?.toFixed(0)} %
                  </Heading>
                  <Pane position={"absolute"} left={0} bottom={0} width={"100%"} height={`${overlap}%`} display={"flex"} alignItems={"center"} justifyContent="center" backgroundColor="#6eb1f4" />
                </Pane>
              </Pane>
            )}
          </Card>
          <Card background={"white"} padding={majorScale(2)} display={"grid"} gap={majorScale(2)}>
            <Heading>Célcsoport(ok)</Heading>
            {survey.targetGroups?.map((targetGroup, index) => {
              return (
                <Pane key={targetGroup.id} display={"flex"} justifyContent={"space-between"} gap={majorScale(2)} alignItems={"center"}>
                  <Pane display={"flex"} alignItems={"center"}>
                    <StatusIndicator dotSize={16} color={index === 0 ? "#3366FF" : "#FFB020"}></StatusIndicator>
                    {targetGroup.name}
                  </Pane>
                  <Pane display={"flex"} gap={majorScale(2)} alignItems={"center"}>
                    <Text>{getNumberOfFillsByGroup(fills, targetGroup)} kitöltő</Text>
                    <Button onClick={() => copyFillLink(targetGroup)}>Kitöltő link másolása</Button>
                  </Pane>
                </Pane>
              );
            })}
          </Card>
          <Pane display={"flex"} flexDirection="column" gap={majorScale(2)}>
            {survey.questions?.map((question, index) => {
              let overlap: null | number = null;
              if (performance) {
                overlap = getQuestionPerformance(question, performance);
              }
              return (
                <Card key={question.id} background={"white"} padding={majorScale(2)} display={"grid"} gap={majorScale(2)}>
                  <Pane display={"flex"} justifyContent={"space-between"}>
                    <Heading>
                      {index + 1}. {question.question.value}
                    </Heading>
                    <Pane>
                      {performance && survey.targetGroups.length > 1 && (
                        <Text display="flex" gap={majorScale(1)}>
                          Egyezés: <Heading>{overlap?.toFixed(2)} %</Heading>
                        </Text>
                      )}
                    </Pane>
                  </Pane>
                  <Chart options={question.options} fills={fills} targetGroups={survey.targetGroups} answers={getAnswersForQuestion(fills, question.id)} />
                </Card>
              );
            })}
          </Pane>
        </Pane>
      )}
    </Pane>
  );
};

export default Results;
