import { majorScale, Pane } from "evergreen-ui";
import Navbar from "./Navbar";
import "./style.css";
import { Outlet, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Dashboard";
import Results from "./Results";
import Fill from "./Fill";
import { AuthProvider } from "./Auth/AuthContext";
import Create from "./Create";
import PrivateRoutes from "./PrivateRoutes";
import Register from "./Register";
import Thankyou from "./Thankyou";
import Invite from "./Invite";
import TargetGroups from "./TargetGroups";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register/:id" element={<Register />} />
          <Route path="/thank-you" element={<Thankyou />} />
          <Route path="/fill/:id" element={<Fill />} />
          <Route element={<PrivateRoutes />}>
            <Route index element={<Dashboard />} />
            <Route path="/create" element={<Create />} />
            <Route path="/invite" element={<Invite />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/target-groups" element={<TargetGroups />} />
            <Route path="/results/:id" element={<Results />} />
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
}

function Layout() {
  return (
    <Pane width={"100vw"} minHeight={"100vh"} height={"100%"}>
      <Navbar />
      <Pane id={"content"} maxWidth={768} marginX={"auto"} padding={majorScale(2)}>
        <Outlet />
      </Pane>
    </Pane>
  );
}
