import { useState, useEffect } from "react";
import { Autocomplete, AutocompleteItem, Button, Card, Heading, IconButton, majorScale, Pane, PlusIcon, Select, StatusIndicator, Textarea, TextInput, TrashIcon } from "evergreen-ui";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { DataService } from "./Auth/services";
import { ISurvey } from "./interfaces.interface";

interface IQuestion {
  id: string;
  question: {
    value: string;
    isValid: boolean;
    isTouched: boolean;
  };
  type: string;
  options: {
    id: string;
    value: string;
    isValid: boolean;
    isTouched: boolean;
  }[];
}

const mockQuestion = (): IQuestion => {
  return {
    id: uuidv4(),
    question: {
      value: "",
      isValid: false,
      isTouched: false,
    },
    type: "single",
    options: [
      {
        id: uuidv4(),
        value: "",
        isValid: false,
        isTouched: false,
      },
    ],
  };
};

export default function Create() {
  const [form, setForm] = useState<ISurvey>({
    id: uuidv4(),
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    questions: [],
    targetGroups: [],
    contact: "",
  });
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [questions, setQuestions] = useState<IQuestion[]>([mockQuestion()]);
  const [targetGroups, setTargetGroups] = useState([{ id: uuidv4(), name: "" }]);
  const [contact, setContact] = useState("");
  const [listOfTargetGroups, setListOfTargetGroups] = useState<{ id: string; name: string }[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    DataService.getTargetGroups().then((res) => {
      setListOfTargetGroups(res);
    });
  }, []);

  useEffect(() => {
    console.log(form);
  }, [form]);

  useEffect(() => {
    setForm({
      ...form,
      title: title,
      description: description,
      startDate: startDate,
      endDate: endDate,
      questions: questions,
      targetGroups: targetGroups,
      contact: contact,
    });
  }, [title, description, startDate, endDate, questions, targetGroups, contact]);

  const addQuestion = () => {
    const prevQuestions = [...questions];
    prevQuestions.push(mockQuestion());
    setQuestions(prevQuestions);
  };

  const addOption = (questionIndex) => {
    const prevQuestions = [...questions];
    prevQuestions[questionIndex].options.push(mockQuestion().options[0]);
    setQuestions(prevQuestions);
  };

  const removeOption = (questionIndex, optionIndex) => {
    let prevQuestions = [...questions];
    prevQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestions(prevQuestions);
  };

  const setQuestionType = (questionIndex, value) => {
    const prevQuestions = [...questions];
    prevQuestions[questionIndex].type = value;
    if (value === "rate") {
      prevQuestions[questionIndex].options = new Array(5).fill(null).map((_, i) => ({
        id: uuidv4(),
        value: (i + 1).toString(),
        isValid: true,
        isTouched: true,
      }));
    }
    setQuestions(prevQuestions);
  };

  const removeQuestion = (questionIndex) => {
    let prevQuestions = [...questions];
    prevQuestions.splice(questionIndex, 1);
    setQuestions(prevQuestions);
  };

  const handleQuestionInputChange = (questionIndex, value) => {
    const prevQuestions = [...questions];
    prevQuestions[questionIndex].question.value = value;
    prevQuestions[questionIndex].question.isTouched = true;
    setQuestions(prevQuestions);
  };

  const handleOptionInputChange = (questionIndex, optionIndex, value) => {
    const prevQuestions = [...questions];
    prevQuestions[questionIndex].options[optionIndex].value = value;
    prevQuestions[questionIndex].options[optionIndex].isTouched = true;
    setQuestions(prevQuestions);
  };

  const handleTargetGroupInputChange = (targetGroupIndex, value) => {
    const prevTargetGroups = [...targetGroups];
    prevTargetGroups[targetGroupIndex].name = value;
    setTargetGroups(prevTargetGroups);
  };

  const handleStartDateInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatDateInputValue(inputValue);
    setStartDate(formattedValue);
  };

  const handleEndDateInputChange = (event) => {
    const inputValue = event.target.value;
    const formattedValue = formatDateInputValue(inputValue);
    setEndDate(formattedValue);
  };

  const formatDateInputValue = (inputValue) => {
    const formattedValue = inputValue.replace(/\D/g, "");
    if (formattedValue.length <= 4) {
      return formattedValue;
    } else if (formattedValue.length <= 6) {
      const year = formattedValue.slice(0, 4);
      const month = formattedValue.slice(4, 6);
      return `${year}/${month}`;
    } else {
      const year = formattedValue.slice(0, 4);
      const month = formattedValue.slice(4, 6);
      const day = formattedValue.slice(6, 8);
      return `${year}/${month}/${day}`;
    }
  };

  const addTargetGroup = () => {
    const prevTargetGroups = [...targetGroups];
    prevTargetGroups.push({ id: uuidv4(), name: "" });
    setTargetGroups(prevTargetGroups);
  };

  const removeTargetGroup = (targetGroupIndex) => {
    const prevTargetGroups = [...targetGroups];
    prevTargetGroups.splice(targetGroupIndex, 1);
    setTargetGroups(prevTargetGroups);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    DataService.createSurvey(form)
      .then((res) => {
        console.log(res);
        window.alert("Sikeresen létrehoztad a kérdőívet!");
        navigate("/dashboard");
      })
      .catch((error) => {
        console.log(error);
        window.alert("Hiba történt a kérdőív létrehozása közben!");
      });
  };

  const stringArrayOfListOfTargetGroups = listOfTargetGroups.map((item) => item.name);

  return (
    <form onSubmit={handleSubmit}>
      <Pane is="section" display={"grid"} gap={majorScale(2)}>
        <Card background={"white"} padding={majorScale(2)} display={"grid"} gap={majorScale(2)}>
          <Heading>Kérdőív létrehozása</Heading>
          <TextInput required size={"large"} placeholder={"Kérdőív címe"} width={"full"} onChange={(e) => setTitle(e.target.value)} />
          <Textarea name="textarea-1" placeholder="Kérdőív leírása" onChange={(e) => setDescription(e.target.value)} />
          <Pane display={"inline-flex"} gap={majorScale(2)} justifyContent={"space-between"}>
            <TextInput required value={startDate} placeholder="Kezdő dátum: Év / Hónap / Nap" onChange={handleStartDateInputChange} maxLength={10} width={"100%"} />
            <TextInput required value={endDate} placeholder="Záró dátum: Év / Hónap / Nap" onChange={handleEndDateInputChange} maxLength={10} width={"100%"} />
          </Pane>
          <TextInput placeholder={"Kapcsolattartó"} width={"full"} onChange={(e) => setContact(e.target.value)} />
        </Card>

        <Card background={"white"} padding={majorScale(2)} display={"grid"} gap={majorScale(2)}>
          <Heading>Célcsoport(ok)</Heading>
          {targetGroups.map((targetGroup, index) => {
            return (
              <Pane display={"flex"} gap={majorScale(2)}>
                <StatusIndicator dotSize={majorScale(2)} color={index === 0 ? "#3366FF" : "#FFB020"}></StatusIndicator>
                <Autocomplete
                  items={stringArrayOfListOfTargetGroups}
                  onChange={(changedItem) => {
                    handleTargetGroupInputChange(index, changedItem);
                  }}
                  allowOtherValues={true}
                >
                  {(props) => {
                    const { getInputProps, getRef } = props;
                    return (
                      <Pane display="flex">
                        <TextInput required placeholder={"Célcsoport neve"} flex={1} ref={getRef} {...getInputProps()} />
                      </Pane>
                    );
                  }}
                </Autocomplete>
                <IconButton onClick={() => removeTargetGroup(index)} type={"button"} icon={TrashIcon} disabled={index === 0} />
              </Pane>
            );
          })}
          <Button onClick={() => addTargetGroup()} type={"button"} iconBefore={PlusIcon} disabled={targetGroups.length > 1}>
            Célcsoport hozzáadása
          </Button>
        </Card>

        {questions.map((question, q) => {
          return (
            <Card background={"white"} padding={majorScale(2)} display={"grid"} gap={majorScale(2)}>
              <Pane display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Heading>{q + 1}. kerdes</Heading>
                <Pane display={"flex"} gap={majorScale(2)}>
                  <Select defaultValue={question.type} onChange={(event) => setQuestionType(q, event.target.value)}>
                    <option value="single">Egy válasz</option>
                    <option value="multi">Tobb válasz</option>
                    <option value="rate">Értékelés</option>
                  </Select>
                  <IconButton onClick={() => removeQuestion(q)} type={"button"} icon={TrashIcon} />
                </Pane>
              </Pane>
              <TextInput
                required
                placeholder={q + 1 + ". kérdés"}
                width={"100%"}
                size={"large"}
                value={question.question.value}
                onChange={(event) => {
                  handleQuestionInputChange(q, event.target.value);
                }}
              />
              <Pane display={"grid"} gap={majorScale(2)}>
                {question.options.map((option, o) => {
                  return (
                    <Pane display={"grid"} gridTemplateColumns={"auto 32px"} gap={majorScale(2)}>
                      <TextInput
                        required
                        placeholder={o + 1 + ". opció"}
                        width={"100%"}
                        value={option.value}
                        onChange={(event) => {
                          handleOptionInputChange(q, o, event.target.value);
                        }}
                      />
                      <IconButton onClick={() => removeOption(q, o)} type={"button"} icon={TrashIcon} />
                    </Pane>
                  );
                })}
              </Pane>
              <Button onClick={() => addOption(q)} type={"button"} iconBefore={PlusIcon}>
                Opció hozzáadása
              </Button>
            </Card>
          );
        })}
        <Button appearance={"default"} type={"button"} size={"large"} iconBefore={PlusIcon} onClick={() => addQuestion()}>
          Kérdés hozzáadása
        </Button>
        <Button type={"submit"} appearance={"primary"} size={"large"}>
          Kérdőív létrehozása
        </Button>
      </Pane>
    </form>
  );
}
