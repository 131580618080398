import { useState, useEffect } from "react";
import { Button, Card, Heading, Text, majorScale, Pane, Radio, Checkbox } from "evergreen-ui";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import { IFill, ISurvey } from "./interfaces.interface";
import { DataService } from "./Auth/services";

export default function Fill() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [survey, setSurvey] = useState<ISurvey | null>(null);
  const [answers, setAnswers] = useState<any[]>([]);

  const [form, setForm] = useState<IFill>({
    answers: [],
    surveyId: "",
    id: "",
    targetGroupId: "",
  });

  useEffect(() => {
    DataService.getSurveyByTargetGroup(id).then((survey) => {
      if (survey && id) {
        setSurvey(survey);
        setForm((prevForm) => ({ ...prevForm, id: uuidv4(), surveyId: survey.id, targetGroupId: id }));
      }
    });
  }, [id]);

  useEffect(() => {
    setForm({ ...form, answers });
    console.log(form);
  }, [answers]);

  /*
  const handleAnswerSelect = (question, option) => {
    const prevAnswers = answers;
    const answerIndex = prevAnswers.findIndex((answer: any) => answer.questionId === question.id);
    const optionIndex = prevAnswers.findIndex((answer: any) => answer.optionId === option.id);
    const newAnswer = {
      questionId: question.id,
      question: question.question.value,
      optionId: option.id,
      option: option.value,
    };
    switch (question.type) {
      case "rate":
      case "single":
        if (answerIndex === -1) {
          prevAnswers.push(newAnswer);
        } else {
          prevAnswers[answerIndex] = newAnswer;
        }
        setAnswers(prevAnswers);
        console.log("rate or single");
        break;
      case "multi":
        if (optionIndex === -1) {
          prevAnswers.push(newAnswer);
        } else {
          prevAnswers.splice(optionIndex, 1);
        }
        setAnswers(prevAnswers);
        console.log("multi");
        break;
      default:
    }
  };
  */

  const handleAnswerSelect = (question, option) => {
    setAnswers((prevAnswers) => {
      const answerIndex = prevAnswers.findIndex((answer: any) => answer.questionId === question.id);
      const optionIndex = prevAnswers.findIndex((answer: any) => answer.questionId === question.id && answer.optionId === option.id);
      const newAnswer = {
        questionId: question.id,
        question: question.question.value,
        optionId: option.id,
        option: option.value,
      };

      switch (question.type) {
        case "rate":
        case "single":
          if (answerIndex === -1) {
            return [...prevAnswers, newAnswer];
          } else {
            return prevAnswers.map((answer: any, index: number) => (index === answerIndex ? newAnswer : answer));
          }
        case "multi":
          if (optionIndex === -1) {
            return [...prevAnswers, newAnswer];
          } else {
            return prevAnswers.filter((answer: any) => !(answer.questionId === question.id && answer.optionId === option.id));
          }
        default:
          return prevAnswers;
      }
    });
  };

  const checkboxIsSelected = (option) => {
    const prevAnswers = [...answers];
    return prevAnswers.some((answer: any) => answer.optionId === option.id);
  };

  const handleSubmit = async (e) => {
    if (survey) {
      e.preventDefault();

      DataService.submitFill(form).then(() => {
        window.alert("Sikeres kitöltés!");
        navigate("/thank-you");
      });

      /* fetch("https://63f72f24e8a73b486af23661.mockapi.io/fills", {
        method: "POST",
        headers: { "content-type": "application/json" },
        // Send your data in the request body as JSON
        body: JSON.stringify({ ...form, id: uuidv4(), surveyId: survey.id }),
        { ...prevForm, id: uuidv4(), surveyId: survey.id, targetGroupId: id }
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            // handle error
            console.log(res);
          }
        })
        .then((fill) => {
          // do something with the new fill
        })
        .catch((error) => {
          console.log(error);
        }); */
    }
  };

  return (
    <Pane display={"grid"} gap={majorScale(2)}>
      {survey && (
        <form onSubmit={(e) => handleSubmit(e)}>
          <Pane padding={majorScale(2)} display={"grid"} gap={majorScale(2)}>
            <Card background={"white"} padding={majorScale(2)} display={"grid"}>
              <Heading marginBottom={majorScale(2)}>{survey.title}</Heading>
              <Text>{survey.description}</Text>
            </Card>
            {survey.questions.map((question, q) => {
              return (
                <Card key={question.id} background={"white"} padding={majorScale(2)} display={"grid"}>
                  <Heading>
                    {q + 1}. {question.question.value}
                  </Heading>
                  {question.type === "single" &&
                    question.options.map((option, o) => {
                      return <Radio key={option.id} size={16} name={question.question.value} label={option.value} onChange={() => handleAnswerSelect(question, option)} />;
                    })}
                  {question.type === "multi" &&
                    question.options.map((option, o) => {
                      return <Checkbox key={option.id} size={16} label={option.value} checked={checkboxIsSelected(option)} onChange={(e) => handleAnswerSelect(question, option)} />;
                    })}
                  {question.type === "rate" && (
                    <Pane display={"flex"} gap={majorScale(5)}>
                      {question.options.map((option, o) => {
                        return <Radio key={option.id} size={16} name={question.question.value} label={option.value} onChange={() => handleAnswerSelect(question, option)} />;
                      })}
                    </Pane>
                  )}
                </Card>
              );
            })}
            <Button type={"submit"} appearance={"primary"} size={"large"}>
              Beküldés
            </Button>
          </Pane>
        </form>
      )}
    </Pane>
  );
}
