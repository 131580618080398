import { db } from "./firebase";
import { collection, addDoc, getDocs, where, query, DocumentData, doc, deleteDoc } from "firebase/firestore";

import { IFill, ISurvey } from "../interfaces.interface";

const DataService = {
  createSurvey: async (survey: ISurvey) => {
    try {
      await addDoc(collection(db, "surveys"), survey);
      console.log("New survey added to the db.");
    } catch (error) {
      console.error("Error adding survey to db:", error);
    }
  },

  getSurveys: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "surveys"));
      const surveys: ISurvey[] = [];
      //@ts-ignore
      querySnapshot.forEach((doc: ISurvey) => {
        surveys.push({
          //@ts-ignore
          ...doc.data(),
        });
      });
      return surveys;
    } catch (error) {
      console.error("Error retrieving surveys from db:", error);
      return [];
    }
  },

  getSurvey: async (surveyId) => {
    try {
      const surveysRef = collection(db, "surveys");
      const querySnapshot = await getDocs(query(surveysRef, where("id", "==", surveyId)));
      if (querySnapshot.empty) {
        console.log("No survey found with the given ID");
        return null;
      }
      const surveyDoc = querySnapshot.docs[0];
      const survey = surveyDoc.data();

      return survey;
    } catch (error) {
      console.error("Error retrieving survey from db:", error);
      return [];
    }
  },

  getSurveyByTargetGroup: async (targetGroupId): Promise<ISurvey | null> => {
    try {
      const surveysRef = collection(db, "surveys");
      const querySnapshot = await getDocs(surveysRef);

      const surveys = querySnapshot.docs.map((doc) => doc.data() as ISurvey);
      const surveyWithTargetGroup = surveys.find((survey) => survey.targetGroups.some((targetGroup) => targetGroup.id === targetGroupId));

      if (!surveyWithTargetGroup) {
        console.log("No survey found with the given targetGroupId");
        return null;
      }

      return surveyWithTargetGroup;
    } catch (error) {
      console.error("Error retrieving survey from db:", error);
      return null;
    }
  },

  getSurveyWithFills: async (surveyId: string): Promise<[ISurvey | null, IFill[] | null]> => {
    try {
      const surveysRef = collection(db, "surveys");
      const fillsRef = collection(db, "fills");

      const [surveySnapshot, fillsSnapshot] = await Promise.all([getDocs(query(surveysRef, where("id", "==", surveyId))), getDocs(query(fillsRef, where("surveyId", "==", surveyId)))]);

      if (surveySnapshot.empty) {
        console.log("No survey found with the given ID");
        return [null, null];
      }

      const surveyDoc = surveySnapshot.docs[0];
      const survey = surveyDoc.data() as ISurvey;

      const fills: IFill[] = fillsSnapshot.docs.map((fillDoc) => {
        return fillDoc.data() as IFill;
      });

      return [survey, fills];
    } catch (error) {
      console.error("Error retrieving survey and it's fills from db:", error);
      return [null, null];
    }
  },

  submitFill: async (fill: IFill): Promise<void> => {
    try {
      const fillsRef = collection(db, "fills");
      await addDoc(fillsRef, fill);
      console.log("Fill submitted successfully.");
    } catch (error) {
      console.error("Error submitting fill:", error);
      throw new Error("Failed to submit fill.");
    }
  },

  submitInvitationCode: async (code: string) => {
    try {
      const codesRef = collection(db, "invitationCodes");
      await addDoc(codesRef, { code: code });
      console.log("Invitation code submitted successfully.");
    } catch (error) {
      console.error("Error submitting invitation code:", error);
    }
  },

  checkInvitationCode: async (code: string) => {
    try {
      const codesRef = collection(db, "invitationCodes");
      const q = query(codesRef, where("code", "==", code));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("Invitation code is not available.");
        return false;
      } else {
        console.log("Invitation code is available.");
        return true;
      }
    } catch (error) {
      console.error("Error checking invitation code availability:", error);
      return false;
    }
  },

  removeInvitationCode: async (code: string) => {
    try {
      const codesRef = collection(db, "invitationCodes");
      const q = query(codesRef, where("code", "==", code));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("Invitation code not found.");
        return;
      }

      const docRef = doc(db, "invitationCodes", querySnapshot.docs[0].id);
      await deleteDoc(docRef);
      console.log("Invitation code removed successfully.");
    } catch (error) {
      console.error("Error removing invitation code:", error);
    }
  },

  submitTargetGroups: async (groups: { id: string; name: string }[]) => {
    try {
      const groupsRef = collection(db, "targetGroups");
      for (const group of groups) {
        await addDoc(groupsRef, group);
      }
      console.log("Array pushed to Firestore successfully!");
    } catch (error) {
      console.error("Error pushing array to Firestore:", error);
    }
  },

  getTargetGroups: async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "targetGroups"));
      const targetGroups: { id: string; name: string }[] = [];
      //@ts-ignore
      querySnapshot.forEach((doc: ISurvey) => {
        targetGroups.push({
          //@ts-ignore
          ...doc.data(),
        });
      });
      return targetGroups;
    } catch (error) {
      console.error("Error retrieving targetGroups from db:", error);
      return [];
    }
  },

  removeSurvey: async (surveyId: string) => {
    try {
      const surveysRef = collection(db, "surveys");
      const q = query(surveysRef, where("id", "==", surveyId));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("Survey not found.");
        return;
      }

      const docRef = doc(db, "surveys", querySnapshot.docs[0].id);
      await deleteDoc(docRef);
      console.log("Survey removed successfully.");
    } catch (error) {
      console.error("Error removing survey:", error);
    }
  },
};

const AuthService = {
  signin: {},
};

export { DataService };
