import { ArrowRightIcon, Button, Icon, majorScale, Pane } from "evergreen-ui";
import { Link, NavLink } from "react-router-dom";
import { useAuth } from "./Auth/AuthContext";

export default function Navbar() {
  const { currentUser, logout } = useAuth();

  return (
    <Pane background={"white"} padding={majorScale(2)} display={"inline-flex"} width={"100%"} justifyContent={"space-between"} alignItems={"center"} borderBottom>
      <Pane id={"logo"}>Tükröző online kérdőív</Pane>

      {currentUser && (
        <Pane id={"right"} display={"flex"} gap={majorScale(1)}>
          <NavLink to="/dashboard">
            <Button appearance="minimal" size={"medium"}>
              Kérdőívek
            </Button>
          </NavLink>
          <NavLink to="/target-groups">
            <Button appearance="minimal" size={"medium"}>
              Célcsoportok
            </Button>
          </NavLink>
          <NavLink to="/invite">
            <Button appearance="minimal" size={"medium"}>
              Meghívás
            </Button>
          </NavLink>
          <Button appearance="minimal" size={"medium"} onClick={logout}>
            Kijelentkezés
          </Button>
        </Pane>
      )}
    </Pane>
  );
}
