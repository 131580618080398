import { Heading, Button, Card, majorScale, Pane, Text, TickIcon } from "evergreen-ui";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { DataService } from "./Auth/services";

export default function Invite() {
  const [generatedLink, setGeneratedLink] = useState<string | null>(null);
  const [code, setCode] = useState<string | null>(null);
  const [isCopied, setIsCopied] = useState(false);

  const handleGenerate = () => {
    const id = uuidv4();
    const link = window.location.hostname + "/register/" + id;
    setCode(id);
    setGeneratedLink(link);
  };

  const handleCopy = () => {
    if (generatedLink) {
      navigator.clipboard.writeText(generatedLink);
    }
    setIsCopied(true);
    if (code && !isCopied) {
      DataService.submitInvitationCode(code);
    }
  };

  return (
    <Card background="white" display={"grid"} gap={majorScale(2)} padding={majorScale(2)}>
      <Pane display={"grid"} gap={majorScale(2)}>
        <Heading>Meghívás</Heading>
        <Text>Regisztrációs link generálással tudunk új felhasználókat adni a rendszerhez.</Text>
        {generatedLink ? (
          <Pane onClick={handleCopy} display="flex" alignItems="center" gap={majorScale(2)}>
            <Text>{generatedLink}</Text>
            {isCopied ? (
              <Button iconAfter={TickIcon} intent="success">
                Másolva
              </Button>
            ) : (
              <Button>Másolás</Button>
            )}
          </Pane>
        ) : (
          <Button size="large" onClick={handleGenerate}>
            Regisztrációs link generálása
          </Button>
        )}
      </Pane>
    </Card>
  );
}
